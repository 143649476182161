import React from 'react';
import './portfolio.css';
import flyfit from '../../assets/img/flyfit-logo.png';
import smp from '../../assets/img/smp-logo.png';
import happyhome from '../../assets/img/happyhome-logo.png';

const Portfolio = () => (
  <div className='container portfolio'>
    <h2>Portfolio</h2>
    <div className="portfolio-icons">
      <a href="#home" className="icon"><img className='smp' src={smp} alt="Southern Maine Painting" /></a>
      <a href="#home" className="icon"><img src={happyhome} alt="Happy Home Computers" /></a>
      <a href="#home" className="icon"><img className='flyfit' src={flyfit} alt="Flyfit" /></a>
    </div>
  </div>
);

export default Portfolio
