import React from 'react';
import './footer.css';
import logo from "../../assets/img/logo-full.png";
import instagram from '../../assets/img/contact-icons/instagram.png';
import linkedin from '../../assets/img/contact-icons/linkedin.png';
import email from '../../assets/img/contact-icons/email.png';
import youtube from '../../assets/img/contact-icons/youtube.png';
import location from '../../assets/img/contact-icons/location.png';

const Footer = () => (
  <div className='container footer'>
    <div className="logo-container">
      <img src={logo} alt="logo" />
      <p>&copy; Rocky Coast Labs LLC., Maine. All Rights Reserved</p>
    </div>
    <div className="links-container">
      <a href="#">Home</a>
      <a href="#">About</a>
      <a href="#">Services</a>
      <div className="social-links">
        <a href="#"><img src={instagram} alt="" /></a>
        <a href="#"><img src={youtube} alt="" /></a>
        <a href="#"><img src={linkedin} alt="" /></a>
        <a href="#"><img src={email} alt="" /></a>
      </div>
    </div>
  </div>
);

export default Footer
