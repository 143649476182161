import { React, useRef } from 'react';

import { Header, Portfolio, About, Contact, Footer } from "./containers";
import { Navbar, Wave } from './components';
import './App.css';

const App = () => {
  const resultRef = useRef(null);

  return (
    <div className="bg-gradient">
      <Navbar />
      <Header resultRef={resultRef}/>
      <Portfolio />
      <About />
      <Wave />
      <Contact ref={resultRef}/>
      <Footer />
    </div>

  )
};

export default App;
