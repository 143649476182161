import React from 'react';
import './about.css';
import jake from '../../assets/img/jake-profile.png';
import anna from '../../assets/img/anna-profile.png';

const About = () => (
  <div className='who-we-are'>
    <div className='container'>
      <h2>Who We Are</h2>
      <div className="profile-container">
        <div className="profile">
          <img src={jake} alt="Jake Loranger" />
          <h3>Jake Loranger</h3>
          <p>Specializing in Frontend Engineering, expertise lies in 
            creating user-friendly interfaces using HTML, CSS, and 
            JavaScript. Additionally, experience as a Sales Specialist 
            involves cultivating client relationships and tailoring 
            solutions for business growth.</p>
        </div>
        <div className="profile">
          <img src={anna} alt="Anna Johnson" />
          <h3>Anna Johnson</h3>
          <p>Proven proficiency as a Backend Engineer includes optimizing 
            server-side functionality and implementing scalable solutions. 
            Simultaneously, serving as an Administrative Coordinator 
            involves ensuring smooth office operations with strong organizational 
            abilities.</p>
        </div>
      </div>
    </div>
  </div>
);

export default About
