import React from 'react';
import './header.css';
import mountain from '../../assets/img/mountains.svg';

const Header = ({ resultRef }) => {
  
  const scrollToContact = () => {
    resultRef.current.scrollIntoView({ behavior: "smooth" });
    console.log("button click");
  };

  return (
    <div>
      <div className="container">
        <div className="hero-wrapper">
          <h1>Shaping Digital Landscapes, <br /> Elevating Local Brands</h1>
          <p>Take your business to new heights through our software solutions,
            spanning UI/UX design, web development,
            and SEO services.</p>
          <div className="button-container">
            <button className='contact-btn' id='contact-btn' onClick={scrollToContact}>Start Your Build</button>
            <button className='services-btn'>What We Provide</button>
          </div>
        </div>
      </div>
      <img className='mountains' src={mountain} alt="" />
    </div>
    )
};

export default Header
