import React, { useRef, useState } from 'react';
import "./form.css";
import emailjs from "@emailjs/browser";

const Form = () => {
    const form = useRef();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_rfwl08e', 'template_xgvrhyw', form.current, 'Isq9UJ1ZYaOBwZ3dX')
            .then((result) => {
                setName('');
                setEmail('');
                setSubject('');
                setMessage('');
                alert("Email sent")
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <form ref={form} className="contact-form-container" onSubmit={sendEmail} id="contact-form">
            <div class="form__div">
                <input name="from_name" type="text" class="form__input" placeholder=" " value={name} onChange={(e) => setName(e.target.value)} />
                <label class="form__label">Name</label>
            </div>
            <div class="form__div">
                <input name="from_email" type="email" class="form__input" placeholder=" " value={email} onChange={(e) => setEmail(e.target.value)} />
                <label class="form__label">Email</label>
            </div>
            <div class="form__div">
                <input name="subject" type="textarea" class="form__input" placeholder=" " value={subject} onChange={(e) => setSubject(e.target.value)} />
                <label class="form__label">Subject</label>
            </div>
            <div class="form__div">
                <textarea name="message" type="text" class="form__input" placeholder=" " value={message} onChange={(e) => setMessage(e.target.value)} />
                <label class="form__label">Message</label>
            </div>
            <button class="submit-btn" type="submit">Submit</button>
        </form>

    )
};

export default Form
