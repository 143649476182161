import React from 'react';
import './contact.css';
import instagram from '../../assets/img/contact-icons/instagram.png';
import linkedin from '../../assets/img/contact-icons/linkedin.png';
import email from '../../assets/img/contact-icons/email.png';
import youtube from '../../assets/img/contact-icons/youtube.png';
import location from '../../assets/img/contact-icons/location.png';
import { Form } from "../../components";
import { forwardRef } from "react";


const Contact = forwardRef((props, ref) => {

  return (
  <div ref={ref} className='container'>
      <div className="contact-container">
      <div className="get-in-touch">
          <h3>Get In Touch</h3>
          <Form/>
        </div>
        <div className="contact-info">
          <h3>Contact Info</h3>
          <div className="contact-link-container">
            <div className="contact-link">
              <img src={location} alt="" />
              <h4>Portland, Maine</h4>
            </div>
            <div className="contact-link">
              <img src={email} alt="" />
              <h4>contact@rockycoastlabs.com</h4>
            </div>
            <div className="contact-link">
              <img src={instagram} alt="" />
              <h4>Instagram</h4>
            </div>
            <div className="contact-link">
              <img src={linkedin} alt="" />
              <h4>Linkedin</h4>
            </div>
            <div className="contact-link">
              <img src={youtube} alt="" />
              <h4>Youtube</h4>
            </div>
            <div className="contact-link">
              <img src={location} alt="" />
              <h4>Github</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Contact
