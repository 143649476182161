import React from 'react'
import "./wave.css";

const Wave = () => {
    return (
        <div class="hero_area">
            <svg class="waves" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                <defs>
                    <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                </defs>
                <g class="parallax">
                    <use href="#gentle-wave" x="48" y="0" fill='none' stroke="#034559" />
                    <use href="#gentle-wave" x="48" y="2" fill='none' stroke="#0D918C"/>
                    <use href="#gentle-wave" x="48" y="4" fill='none' stroke="#11AF82"/>
                    <use href="#gentle-wave" x="48" y="6" fill='none' stroke="#A6DECE"/>
                    <use href="#gentle-wave" x="48" y="7.5" fill='none' stroke="#BDF0DF" />
                </g>
            </svg>
        </div>
    )
};

export default Wave
